import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDateTimePicker as KeyboardDatePickerComponent,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import ptLocale from "date-fns/locale/pt-BR";
import { useEffect, useState } from "react";

const KeyboardDatePicker = props => {
  const [dateAndHours, setDateAndHours] = useState(new Date());
  const [pageSize, setPageSize] = useState(window.innerWidth);
  const [isOpen, setIsOpen] = useState(false);

  const handleDateChange = value => {
    props.onChange(value);
    setDateAndHours(value);
    setIsOpen(false);
  };

  useEffect(() => {
    const updatePageSize = e => {
      setPageSize(e.target.innerWidth);
    };

    window.addEventListener("resize", updatePageSize);
    return () => {
      window.removeEventListener("resize", updatePageSize);
    };
  }, []);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
      <KeyboardDatePickerComponent
        views={["date", "hours"]}
        format="dd/MM/yy HH:00"
        open={isOpen}
        onClose={() => setIsOpen(false)}
        onOpen={() => setIsOpen(true)}
        value={dateAndHours}
        {...props}
        variant={pageSize > 700 ? "inline" : "dialog"}
        onChange={handleDateChange}
        openTo="date"
      />
    </MuiPickersUtilsProvider>
  );
};

export default KeyboardDatePicker;
