import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from "@material-ui/core";
import { Button } from "components";
import { usePopup } from "hooks/usePopup";
import { useState } from "react";
import { useForm } from "react-hook-form";
import InputMask from "react-input-mask";
import sellerService from "services/sellerService";
import { Dialog } from "shared";
import * as Yup from "yup";

const schema = Yup.object().shape({
  code: Yup.string().required("Código obrigatório"),
  name: Yup.string().required("Nome obrigatório"),
  phone_number: Yup.mixed()
    .notRequired()
    .nullable()
    .test("phone_number", "Número obrigatório", value => {
      return !!(
        value.replace(/[^0-9]/g, "").length >= 10 &&
        value.replace(/[^0-9]/g, "").length <= 11
      );
    }),
  pix_key: Yup.string(),
  pix_key_type: Yup.string().when("pix_key", {
    is: pix_key => !!pix_key,
    then: Yup.string().required("O campo Tipo da chave é obrigatório"),
    otherwise: Yup.string()
  }),
  beneficiary: Yup.string().when("pix_key", {
    is: pix_key => !!pix_key,
    then: Yup.string().required("O campo Beneficiário é obrigatório"),
    otherwise: Yup.string()
  })
});

export const ModalCreated = ({ openModal, setOpenModal, refresh }) => {
  const { addPopup } = usePopup();
  const [isLoading, setIsLoading] = useState(false);
  const { register, handleSubmit, formState, reset } = useForm({
    resolver: yupResolver(schema)
  });
  const { errors } = formState;

  const handleClose = () => {
    reset({});
    setOpenModal(false);
  };

  const handleRegister = async data => {
    try {
      setIsLoading(true);
      await sellerService.postSeller(data);

      addPopup({
        type: "success",
        title: "Seller registrado com sucesso."
      });
      handleClose();
      refresh();
    } catch (error) {
      if (error?.response?.status !== 401) {
        if (
          error?.response?.data?.includes(
            "One of the edited values already exists"
          )
        ) {
          addPopup({
            type: "error",
            title: "Um dos valores já existe"
          });

          return;
        }

        addPopup({
          type: "error",
          title: "Desculpe, não foi possível adicionar o Seller."
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const { onChange, onBlur, ref, name } = register("phone_number");

  return (
    <Dialog
      open={openModal}
      handleClose={handleClose}
      title="Registro de Fornecedor"
    >
      <Box component="form" onSubmit={handleSubmit(handleRegister)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              {...register("name")}
              fullWidth
              error={!!errors?.name}
              helperText={errors?.name?.message}
              variant="outlined"
              label="Nome do fornecedor"
              autoComplete="off"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputMask
              mask="(99) 99999-9999"
              maskChar=" "
              onChange={onChange}
              onBlur={onBlur}
            >
              {() => (
                <TextField
                  ref={ref}
                  name={name}
                  fullWidth
                  error={!!errors?.phone_number}
                  helperText={errors?.phone_number?.message}
                  variant="outlined"
                  label="Celular"
                />
              )}
            </InputMask>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              {...register("code")}
              error={!!errors?.code}
              helperText={errors?.code?.message}
              variant="outlined"
              label="Código do fornecedor"
              autoComplete="off"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              {...register("bank")}
              variant="outlined"
              label="Banco"
              autoComplete="off"
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <TextField
              fullWidth
              {...register("agency")}
              variant="outlined"
              label="Agência"
              autoComplete="off"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              {...register("account")}
              variant="outlined"
              label="Conta"
              autoComplete="off"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              {...register("pix_key")}
              variant="outlined"
              label="Chave pix"
              autoComplete="off"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="select-pix-type">Tipo da chave</InputLabel>
              <Select
                {...register("pix_key_type")}
                label="Tipo da chave"
                labelId="select-pix-type"
                autoComplete="off"
                error={!!errors?.pix_key_type}
                helperText={errors?.pix_key_type?.message}
              >
                <MenuItem value={"cpf"}>CPF</MenuItem>
                <MenuItem value={"cnpj"}>CNPJ</MenuItem>
                <MenuItem value={"email"}>E-mail</MenuItem>
                <MenuItem value={"phone"}>Celular</MenuItem>
                <MenuItem value={"random"}>Chave aleatória</MenuItem>
              </Select>
              <FormHelperText>{errors?.pix_key_type?.message}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              fullWidth
              {...register("beneficiary")}
              variant="outlined"
              label="Beneficiário"
              autoComplete="off"
              error={!!errors?.beneficiary}
              helperText={errors?.beneficiary?.message}
            />
          </Grid>

          <Grid container item justifyContent="flex-end">
            <Button isLoading={isLoading} type="submit" variant="contained">
              Registrar
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
};
