import {
  Button as ButtonMui,
  CircularProgress,
  Typography
} from "@material-ui/core";

import { useStyles } from "./styles";

export const Button = ({
  children,
  isLoading,
  disabled,
  isFullScreen,
  className,
  color,
  ...rest
}) => {
  const classes = useStyles({ warning: color === "warning" });

  return (
    <ButtonMui
      className={`${classes.root} ${
        isFullScreen ? classes.fullScreen : classes.screenDefault
      } ${className}`}
      color={color}
      {...rest}
      disabled={isLoading || disabled}
    >
      {isLoading && <CircularProgress size={25} className={classes.loading} />}

      <Typography style={{ opacity: isLoading ? 0 : 1 }}>{children}</Typography>
    </ButtonMui>
  );
};
