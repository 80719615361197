import { makeStyles, darken } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    justifyContent: "center",
    borderRadius: "0.5rem",
    paddingLeft: "0.7rem",
    paddingRight: "0.7rem",
    background: props => props.warning && theme.palette.warning.main,
    "&:hover": {
      background: props =>
        props.warning ? darken(theme.palette.warning.main, 0.05) : undefined
    },
    "&.MuiButton-text": {
      color: "#757575"
    },
    "&.MuiButton-text span": {
      fontWeight: 600
    },
    "&.MuiButton-text:hover": {
      background: darken("#fff", 0.05)
    }
  },
  fullScreen: {
    "@media(max-width: 400px)": {
      width: "100%"
    }
  },
  loading: {
    color: "#BF98FF",
    position: "absolute"
  }
}));
