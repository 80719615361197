import { yupResolver } from "@hookform/resolvers/yup";
import {
  TextField,
  Button,
  Box,
  useTheme,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  IconButton,
  Grid,
  Divider,
  Typography
} from "@material-ui/core";
import { Title } from "components/Title";
import { usePopup } from "hooks/usePopup";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { MdOutlineDelete } from "react-icons/md";
import productBaseService from "services/productBaseService";
import { AutoCompleteProductBase, Dialog } from "shared";
import { v4 } from "uuid";
import * as Yup from "yup";

export const ModalUpdateWeight = ({ isOpen, refresh, close }) => {
  const theme = useTheme();
  const { addPopup } = usePopup();
  const [lastSample, setLastSample] = useState();
  const [samples, setSamples] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const schema = Yup.object().shape({
    productBaseId: Yup.mixed().required("Produto base obrigatório"),
    samples: Yup.mixed()
      .notRequired()
      .nullable()
      .test(
        "samples",
        "É obrigatório pelo menos uma pessagem",
        value => samples.length !== 0
      )
  });

  const { handleSubmit, formState, reset, control } = useForm({
    resolver: yupResolver(schema)
  });

  const { errors } = formState;

  const handleClose = () => {
    close(false);
    setSamples([]);
    setLastSample("");
    reset({});
  };

  const onUpdateHandler = async data => {
    try {
      setIsLoading(true);
      const { productBaseId } = data;
      const total = samples.reduce((acc, item) => acc + Number(item.value), 0);
      const average = total / samples.length;
      await productBaseService.postProductSample({
        productBaseId: productBaseId.id,
        mean: average
      });
      addPopup({
        type: "success",
        title: `Peso do produto "${data.productBaseId?.name}" foi atualizado.`
      });
      handleClose();
      refresh();
    } catch (error) {
      if (error?.response?.status !== 401) {
        addPopup({
          type: "error",
          title: `Erro ao atualizar produto base "${data.productBaseId.name}".`
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddSample = () => {
    if (lastSample) {
      setSamples(state => [
        ...state,
        {
          value: lastSample,
          key: v4()
        }
      ]);
      setLastSample("");
    }
  };

  const handleRemoveSample = key => {
    setSamples(state => state.filter(item => item.key !== key));
  };

  return (
    <Dialog
      open={isOpen}
      handleClose={handleClose}
      title="Atualização de pesagem"
    >
      <Box component="form" onSubmit={handleSubmit(onUpdateHandler)} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <AutoCompleteProductBase
              control={control}
              name="productBaseId"
              errors={errors}
            />
          </Grid>

          <Grid container item spacing={2}>
            <Grid item xs={9}>
              <TextField
                onChange={event => setLastSample(event.target.value)}
                type="number"
                fullWidth
                variant="outlined"
                required
                label="Peso (kg)"
                value={lastSample}
              />
            </Grid>
            <Grid item xs={3}>
              <Button
                style={{ height: "100%" }}
                fullWidth
                size="large"
                variant="contained"
                onClick={handleAddSample}
              >
                Adicionar
              </Button>
            </Grid>

            <Grid container item>
              <Box
                borderTop={`2px solid ${theme.palette.primary.main}`}
                mt="1rem"
                pt="1rem"
                width="100%"
              >
                <Title style={{ fontSize: "1.4rem" }}>Pesagens:</Title>
                <Typography
                  component="strong"
                  style={{
                    display: "block",
                    color: "#f44336",
                    fontSize: "12px",
                    fontWeight: "lighter"
                  }}
                >
                  {samples.length === 0 && errors?.samples?.message}
                </Typography>
                <List dense>
                  {samples.map(sample => (
                    <>
                      <ListItem key={sample.key} disableGutters>
                        <ListItemIcon>
                          <IconButton
                            onClick={() => handleRemoveSample(sample.key)}
                          >
                            <MdOutlineDelete
                              color={theme.palette.warning.main}
                            />
                          </IconButton>
                        </ListItemIcon>

                        <ListItemText
                          primary={`${sample.value} kg`}
                          primaryTypographyProps={{
                            style: { fontWeight: 600 }
                          }}
                        />
                      </ListItem>
                      <Divider style={{ background: "#7f7f7f" }} />
                    </>
                  ))}
                </List>
              </Box>
            </Grid>
          </Grid>

          <Grid container item justifyContent="flex-end">
            <Button
              size="large"
              variant="contained"
              type="submit"
              isLoading={isLoading}
            >
              Salvar
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
};
