export const formatDate = (date, dateSeparator = "-") => {
  const d = new Date(date);
  const [yyyy, mm, dd] = [d.getFullYear(), d.getMonth(), d.getDate()];
  const formatString = value => String(value).padStart(2, "0");
  return `${formatString(yyyy)}${dateSeparator}${formatString(
    mm + 1
  )}${dateSeparator}${formatString(dd)}`;
};

export const splitDate = date => {
  const dateToSplit = date?.split("/");

  return new Date(dateToSplit[2], dateToSplit[1] - 1, dateToSplit[0]);
};
