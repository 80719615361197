import { Layout } from "layouts";
import { AlignmentWithSeller } from "pages/private/AlignmentWithSeller";
import AssetsBalance from "pages/private/AssetsBalance";
import AssetsLedger from "pages/private/AssetsLedger";
import AssetsList from "pages/private/AssetsList";
import AssetsSummary from "pages/private/AssetsSummary";
import AssetsTransactions from "pages/private/AssetsTransactions";
import { BatchCount } from "pages/private/BatchCount";
import { BatchReturn } from "pages/private/BatchReturn";
import { BatchSelectionPage } from "pages/private/BatchSelection";
import BatchStorage from "pages/private/BatchStorage";
import { BlitzRules } from "pages/private/BlitzRules";
import BlockedStock from "pages/private/BlockedStock";
import { CardKanban } from "pages/private/CardsKanban";
import { ConfigurationsPage } from "pages/private/ConfigurationsPage";
import { ConfigureProductsBase } from "pages/private/ConfigureProductsBase";
import ConfirmBatch from "pages/private/ConfirmBatch";
import { Dashboard } from "pages/private/Dashboard";
import Default from "pages/private/Default";
import { DepotLedger } from "pages/private/DepotLedger";
import Depots from "pages/private/Depots";
import { KanbanSelectionGroup } from "pages/private/KanbanSelectionGroup";
import MigrationRules from "pages/private/MigrationRules";
import { NotFound } from "pages/private/NotFound";
import { PackagingTypesPage } from "pages/private/PackagingType";
import ProductBase from "pages/private/ProductBase";
import { ProductBatch } from "pages/private/ProductBatch";
import { ProductBatchDetail } from "pages/private/ProductBatch/Detail";
import { ProductCategories } from "pages/private/ProductCategories";
import { ProductStorageStockType } from "pages/private/ProductStorageStockType";
import { ProductSubBatch } from "pages/private/ProductSubBatch";
import { ProductSubBatchDetail } from "pages/private/ProductSubBatch/Detail";
import { ProductSubCategories } from "pages/private/ProductSubCategories";
import PurchaseForecast from "pages/private/PurchaseForecast";
import { ReceiptConference } from "pages/private/ReceiptConference";
import Receivement from "pages/private/Receivement";
import ReturnAnalysis from "pages/private/ReturnAnalysis";
import SelectionGroups from "pages/private/SelectionGroups";
import RegisterSeller from "pages/private/Seller";
import { SellerDashboard } from "pages/private/SellerDashboard";
import SellerItemPage from "pages/private/SellerItem";
import { RankingSeller } from "pages/private/SellerRanking";
import { SensitivityLevelsPage } from "pages/private/SensitivityLevels";
import Sku from "pages/private/Sku";
import StockHistory from "pages/private/StockHistory";
import StoragePage from "pages/private/StorageControl";
import { StoredBatches } from "pages/private/StoredBatches";
import { HistoryStoredBatches } from "pages/private/StoredBatches/HistoryStoredBatches";
import SummaryPage from "pages/private/Summary";
import SummaryFisicPage from "pages/private/SummaryFisic";
import { SummaryStockSku } from "pages/private/SummaryStockSku";
import { Users } from "pages/private/Users";
import VirtualSummary from "pages/private/VirtualSummary";
import { WarehouseType } from "pages/private/WarehouseType";
import { Switch } from "react-router-dom";

import Route from "../Route";

const RoutesPrivate = () => {
  return (
    <Layout>
      <Switch>
        <Route
          path="/app"
          exact
          permissionsRoute={[]}
          component={Default}
          isPrivate
        />
        <Route
          path="/app/404"
          exact
          permissionsRoute={[]}
          component={NotFound}
          isPrivate
        />

        <Route
          path="/app/dashboard"
          exact
          permissionsRoute={["Stock View", "Tech", "Admin"]}
          component={Dashboard}
          isPrivate
        />

        <Route
          path="/app/dashboard/kanban"
          exact
          permissionsRoute={["Stock View", "Tech", "Admin"]}
          component={CardKanban}
          isPrivate
        />

        <Route
          path="/app/dashboard/kanban-grupo-de-selecao"
          exact
          permissionsRoute={["Stock View", "Tech", "Admin"]}
          component={KanbanSelectionGroup}
          isPrivate
        />

        <Route
          path="/app/analise-devolucao"
          exact
          permissionsRoute={["Stock View", "Tech", "Admin"]}
          component={ReturnAnalysis}
          isPrivate
        />

        <Route
          path="/app/recebimento"
          exact
          permissionsRoute={["Reception", "Tech", "Admin"]}
          component={Receivement}
          isPrivate
        />
        <Route
          path="/app/confirm-batch"
          exact
          permissionsRoute={["Stock View", "Estoque", "Tech", "Admin"]}
          component={ConfirmBatch}
          isPrivate
        />
        <Route
          path="/app/batch-return"
          exact
          permissionsRoute={["Stock View", "Estoque", "Tech", "Admin"]}
          component={BatchReturn}
          isPrivate
        />
        <Route
          path="/app/alinhamento-com-o-seller"
          exact
          permissionsRoute={["Stock View", "Estoque", "Tech", "Admin"]}
          component={AlignmentWithSeller}
          isPrivate
        />
        <Route
          path="/app/estoque-extrato"
          exact
          permissionsRoute={["Stock View", "Estoque", "Tech", "Admin"]}
          component={StoragePage}
          isPrivate
        />
        <Route
          path="/app/estoque-resumo"
          exact
          permissionsRoute={["Stock View", "Estoque", "Tech", "Admin"]}
          component={SummaryPage}
          isPrivate
        />
        <Route
          path="/app/estoque-resumo-virtual"
          exact
          permissionsRoute={["Stock View", "Estoque", "Tech", "Admin"]}
          component={VirtualSummary}
          isPrivate
        />
        <Route
          path="/app/estoque-resumo-sku"
          exact
          permissionsRoute={["Stock View", "Estoque", "Tech", "Admin"]}
          component={SummaryStockSku}
          isPrivate
        />
        <Route
          path="/app/estoque-fisico"
          exact
          permissionsRoute={["Stock View", "Estoque", "Tech", "Admin"]}
          component={SummaryFisicPage}
          isPrivate
        />

        <Route
          path="/app/estoque-bloqueado"
          exact
          permissionsRoute={["Stock View", "Estoque", "Tech", "Admin"]}
          component={BlockedStock}
          isPrivate
        />

        <Route
          path="/app/historico-estoque"
          exact
          permissionsRoute={["Stock View", "Estoque", "Tech", "Admin"]}
          component={StockHistory}
          isPrivate
        />
        <Route
          path="/app/fornecedores"
          exact
          permissionsRoute={["Stock View", "Tech", "Admin"]}
          component={RegisterSeller}
          isPrivate
        />
        <Route
          path="/app/fornecedores/:sellerId/dashboard"
          exact
          permissionsRoute={["Stock View", "Tech", "Admin"]}
          component={SellerDashboard}
          isPrivate
        />
        <Route
          path="/app/fornecedores/ranking"
          exact
          permissionsRoute={["Stock View", "Tech", "Admin"]}
          component={RankingSeller}
          isPrivate
        />
        <Route
          path="/app/produto-por-fornecedor"
          exact
          permissionsRoute={["Stock View", "Tech", "Admin"]}
          component={SellerItemPage}
          isPrivate
        />
        <Route
          path="/app/produtos"
          exact
          permissionsRoute={["Stock View", "Tech", "Admin"]}
          component={ProductBase}
          isPrivate
        />
        <Route
          path="/app/produtos/categorias"
          exact
          permissionsRoute={["Stock View", "Tech", "Admin"]}
          component={ProductCategories}
          isPrivate
        />
        <Route
          path="/app/produtos/subcategorias"
          exact
          permissionsRoute={["Stock View", "Tech", "Admin"]}
          component={ProductSubCategories}
          isPrivate
        />
        <Route
          path="/app/produtos/estocagem"
          exact
          permissionsRoute={["Stock View", "Tech", "Admin"]}
          component={ProductStorageStockType}
          isPrivate
        />
        <Route
          path="/app/regra-de-migracao"
          exact
          permissionsRoute={["Tech", "Admin"]}
          component={MigrationRules}
          isPrivate
        />
        <Route
          path="/app/skus"
          exact
          permissionsRoute={["Stock View", "Tech", "Admin"]}
          component={Sku}
          isPrivate
        />
        <Route
          path="/app/previsao-de-compra"
          exact
          permissionsRoute={["Previsão", "Tech", "Admin"]}
          component={PurchaseForecast}
          isPrivate
        />
        <Route
          path="/app/ativos"
          exact
          permissionsRoute={["Stock View", "Estoque", "Tech", "Admin"]}
          component={AssetsList}
          isPrivate
        />
        <Route
          path="/app/ativos-saldo-fornecedor"
          exact
          permissionsRoute={["Stock View", "Estoque", "Tech", "Admin"]}
          component={AssetsBalance}
          isPrivate
        />
        <Route
          path="/app/ativos-transacoes"
          exact
          permissionsRoute={["Stock View", "Estoque", "Tech", "Admin"]}
          component={AssetsTransactions}
          isPrivate
        />
        <Route
          path="/app/ativos-extrato"
          exact
          permissionsRoute={["Stock View", "Estoque", "Tech", "Admin"]}
          component={AssetsLedger}
          isPrivate
        />
        <Route
          path="/app/ativos-resumo"
          exact
          permissionsRoute={["Stock View", "Estoque", "Tech", "Admin"]}
          component={AssetsSummary}
          isPrivate
        />
        <Route
          path="/app/depositos/:warehouseTypeId"
          exact
          permissionsRoute={["Stock View", "Estoque", "Tech", "Admin"]}
          component={Depots}
          isPrivate
        />
        <Route
          path="/app/regra-da-blitz"
          exact
          permissionsRoute={["Previsão", "Tech", "Admin", "Stock View"]}
          component={BlitzRules}
          isPrivate
        />
        <Route
          path="/app/conferencia-de-recebimento"
          exact
          permissionsRoute={["Previsão", "Tech", "Admin", "Stock View"]}
          component={ReceiptConference}
          isPrivate
        />
        <Route
          path="/app/selecao"
          exact
          permissionsRoute={["Previsão", "Tech", "Admin", "Stock View"]}
          component={BatchSelectionPage}
          isPrivate
        />
        <Route
          path="/app/lotes"
          exact
          permissionsRoute={["Previsão", "Tech", "Admin", "Stock View"]}
          component={ProductBatch}
          isPrivate
        />
        <Route
          path="/app/lotes/:batchId"
          exact
          permissionsRoute={["Previsão", "Tech", "Admin", "Stock View"]}
          component={ProductBatchDetail}
          isPrivate
        />
        <Route
          path="/app/lotes-armazenados/:packagingTypeId/:batchId"
          exact
          permissionsRoute={["Previsão", "Tech", "Admin", "Stock View"]}
          component={HistoryStoredBatches}
          isPrivate
        />
        <Route
          path="/app/sub-lotes"
          exact
          permissionsRoute={["Previsão", "Tech", "Admin", "Stock View"]}
          component={ProductSubBatch}
          isPrivate
        />
        <Route
          path="/app/sub-lotes/:subBatchId"
          exact
          permissionsRoute={["Previsão", "Tech", "Admin", "Stock View"]}
          component={ProductSubBatchDetail}
          isPrivate
        />
        <Route
          path="/app/usuarios"
          exact
          permissionsRoute={["Tech", "Admin"]}
          component={Users}
          isPrivate
        />
        <Route
          path="/app/batch-count"
          exact
          permissionsRoute={["Previsão", "Tech", "Admin", "Stock View"]}
          component={BatchCount}
          isPrivate
        />
        <Route
          path="/app/grupos-selecao"
          exact
          permissionsRoute={["Tech", "Admin"]}
          component={SelectionGroups}
          isPrivate
        />
        <Route
          path="/app/armazens"
          exact
          permissionsRoute={["Admin", "Tech", "Stock View", "Estoque"]}
          component={WarehouseType}
          isPrivate
        />
        <Route
          path="/app/armazens-extrato"
          exact
          permissionsRoute={["Admin", "Tech", "Stock View", "Estoque"]}
          component={DepotLedger}
          isPrivate
        />
        <Route
          path="/app/armazenamento"
          exact
          permissionsRoute={["Admin", "Tech", "Stock View", "Estoque"]}
          component={BatchStorage}
          isPrivate
        />
        <Route
          path="/app/configurar-produtos-base"
          exact
          permissionsRoute={["Admin", "Tech", "Stock View", "Estoque"]}
          component={ConfigureProductsBase}
          isPrivate
        />
        <Route
          path="/app/lotes-armazenados"
          exact
          permissionsRoute={["Admin", "Tech", "Stock View", "Estoque"]}
          component={StoredBatches}
          isPrivate
        />
        <Route
          path="/app/embalagens"
          exact
          permissionsRoute={["Admin", "Tech", "Stock View", "Estoque"]}
          component={PackagingTypesPage}
          isPrivate
        />
        <Route
          path="/app/config"
          exact
          permissionsRoute={["Admin", "Tech"]}
          component={ConfigurationsPage}
          isPrivate
        />
        <Route
          path="/app/config/niveis-sensibilidade"
          exact
          permissionsRoute={["Admin", "Tech"]}
          component={SensitivityLevelsPage}
          isPrivate
        />

        <Route
          path="*"
          exact
          permissionsRoute={[]}
          component={NotFound}
          isPrivate
        />
      </Switch>
    </Layout>
  );
};

export default RoutesPrivate;
