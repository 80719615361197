import "react-datepicker/dist/react-datepicker.css";

import { Typography, useTheme } from "@material-ui/core";
import { format } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";
import React, { useState, useEffect } from "react";
import { registerLocale } from "react-datepicker";

import { DateFilterContent, StyledDatePicker } from "./style";

registerLocale("ptBR", ptBR);

const DateRangeFilter = ({ onChange, params }) => {
  const [selectedDates, setSelectedDates] = useState([null, null]);

  const handleChange = dates => {
    const [startDate, endDate] = dates;

    setSelectedDates(dates);

    if (startDate && endDate) {
      const formattedStartDate = format(startDate, "yyyy-MM-dd");
      const formattedEndDate = format(endDate, "yyyy-MM-dd");
      onChange({ startDate: formattedStartDate, endDate: formattedEndDate });
    }
  };

  useEffect(() => {
    const [startDate, endDate] = selectedDates;

    setSelectedDates([startDate || new Date(), endDate || new Date()]);
  }, [params.startDate, params.endDate]);

  const theme = useTheme();

  return (
    <DateFilterContent>
      <Typography>Filtrar: </Typography>
      <StyledDatePicker
        selected={selectedDates[0]}
        onChange={handleChange}
        startDate={selectedDates[0]}
        endDate={selectedDates[1]}
        selectsRange
        dateFormat="dd/MM/yyyy"
        locale="ptBR"
        calendarClassName="custom-calendar"
      />

      <style jsx>{`
        .react-datepicker__day--selected,
        .react-datepicker__day--selected:hover,
        .react-datepicker__day--keyboard-selected {
          background-color: ${theme.palette.primary.main} !important;
        }

        .react-datepicker__day--in-selecting-range {
          background-color: ${theme.palette.primary.main} !important;
        }

        .react-datepicker__day--in-range {
          background-color: ${theme.palette.primary.main} !important;
        }

        .react-datepicker__header {
          background-color: white;
          color: ${theme.palette.primary.contrastText};
        }
      `}</style>
    </DateFilterContent>
  );
};

export default DateRangeFilter;
