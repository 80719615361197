import { Grid, Box, TextField, IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { Button } from "components";
import { usePopup } from "hooks/usePopup";
import React, { useEffect, useState } from "react";
import assetService from "services/assetsService";
import { AutoCompleteAssets, AutoCompleteSeller, Dialog } from "shared";
import FileUpload from "shared/FileUpload";
import { toTitle } from "utils/toTitle";

const initialAssetForm = {
  sellerId: null,
  sellerCode: "",
  sellerName: "",
  transactionId: "",
  assetSignatureUrl: null,
  assets: [
    {
      id: null,
      assetId: null,
      assetName: "",
      quantity: 0,
      uniqueKey: Math.random().toString(36)
    }
  ]
};

export const DialogEdit = props => {
  const { isOpen, setIsOpen, refresh, transactionId } = props;
  const { addPopup } = usePopup();
  const [isLoading, setIsLoading] = useState(false);
  const [isBtnDisabled, setBtn] = useState(true);
  const [assetForm, setAssetForm] = useState(initialAssetForm);
  const [removedAssets, setRemovedAssets] = useState([]);
  const [transaction, setTransaction] = useState({});
  const [transactionCompleted, setTransactionCompleted] = useState(false);

  const handleFileSelect = file => {
    setAssetForm(prevState => ({
      ...prevState,
      assetSignatureUrl: file
    }));
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        if (transactionId) {
          const { data } = await assetService.getTransaction(transactionId);

          const assetData = data.ledgersTransaction.map(asset => ({
            id: asset.id,
            assetName: asset.assetName,
            assetId: asset.assetId,
            quantity: asset.quantity,
            uniqueKey: Math.random().toString(36)
          }));

          setAssetForm({
            id: data.id,
            sellerId: data.sellerId,
            sellerCode: data.sellerCode,
            sellerName: data.sellerName,
            assetSignatureUrl: data.assetSignatureUrl,
            transactionId: transactionId,
            assets: assetData.length > 0 ? assetData : initialAssetForm.assets
          });

          setTransaction(data);

          if (data.status === "fechada") {
            setTransactionCompleted(true);
          }
        }
      } catch (error) {
        addPopup({ type: "error", title: "Erro ao carregar transação" });
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [transactionId]);

  const updateTransaction = async () => {
    try {
      setIsLoading(true);
      const payload = {
        sellerId: assetForm.sellerId,
        sellerCode: assetForm.sellerCode,
        sellerName: assetForm.sellerName
      };

      if (assetForm.assetSignatureUrl) {
        payload.assetSignatureUrl = assetForm.assetSignatureUrl;
      }

      await assetService.updateTransaction(payload, transactionId);
    } catch (err) {
      addPopup({
        type: "error",
        title: "Erro ao atualizar transação"
      });
    } finally {
      setIsLoading(false);
    }
  };

  const createOrUpdateLedger = async () => {
    const payload = {
      assets: assetForm.assets.map(asset => ({
        ...asset,
        sellerCode: assetForm.sellerCode,
        sellerId: assetForm.sellerId,
        sellerName: assetForm.sellerName,
        transactionId: assetForm.transactionId
      }))
    };

    try {
      setIsLoading(true);
      await assetService.createOrUpdateLedger(payload.assets);

      if (removedAssets.length > 0) {
        setRemovedAssets([]);
        await assetService.deleteLedgers(removedAssets);
      }
    } catch (err) {
      addPopup({
        type: "error",
        title: "Erro ao completar transação"
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onSubmitHandler = async e => {
    e.preventDefault();

    let transactionHasChanged = false;

    // Checa se houve alguma alteração no fornecedor ou na assinatura
    if (
      assetForm.sellerName !== transaction.sellerName ||
      assetForm.assetSignatureUrl !== transaction.assetSignatureUrl
    ) {
      transactionHasChanged = true;
      await updateTransaction();
    }

    // Checa se houve alguma alteração nos ativos
    if (
      assetForm.assets.length !== transaction.ledgersTransaction.length ||
      assetForm.assets.some((asset, index) => {
        return (
          asset.assetId !== transaction.ledgersTransaction[index].assetId ||
          asset.quantity !== transaction.ledgersTransaction[index].quantity
        );
      })
    ) {
      transactionHasChanged = true;
      await createOrUpdateLedger();
    }

    if (transactionHasChanged) {
      addPopup({
        type: "success",
        title: "Transação atualizada com sucesso"
      });
    }

    setIsOpen(false);
    setAssetForm(initialAssetForm);
    setTransactionCompleted(false);
    refresh();
  };

  const handleClose = () => {
    setAssetForm(initialAssetForm);
    setTransaction({});
    setTransactionCompleted(false);
    setIsOpen(false);
  };

  const checkForm = form => {
    const { assetSignatureUrl, ...formWithoutSignature } = form;

    const mainFormValid = Object.values(formWithoutSignature).every(
      value => !!value
    );

    if (!mainFormValid) return false;

    const assetsValid = formWithoutSignature.assets.every(asset => {
      return Object.values(asset).every(value => !!value);
    });

    return assetsValid;
  };

  useEffect(() => {
    setBtn(!checkForm(assetForm));
  }, [assetForm]);

  const handleAsset = (value, index) => {
    const updatedAssets = [...assetForm.assets];
    updatedAssets[index] = {
      assetId: parseInt(value?.id),
      assetName: value?.name,
      quantity: updatedAssets[index]?.quantity || 0,
      uniqueKey: updatedAssets[index]?.uniqueKey || Math.random().toString(36)
    };

    setAssetForm(prevState => ({ ...prevState, assets: updatedAssets }));
  };

  const handleQty = (value, index) => {
    const updatedAssets = assetForm.assets.map((asset, idx) => {
      if (idx === index) {
        return {
          ...asset,
          quantity: Number(value)
        };
      }
      return asset;
    });

    setAssetForm(prevState => ({ ...prevState, assets: updatedAssets }));
  };

  const handleAssetQuantity = () => {
    setAssetForm(prevState => ({
      ...prevState,
      assets: [
        ...prevState.assets,
        {
          id: null,
          assetId: null,
          assetName: "",
          quantity: 0,
          uniqueKey: Math.random().toString(36)
        }
      ]
    }));
  };

  const handleRemoveAsset = index => {
    const updatedAssets = [...assetForm.assets];
    const removedAsset = updatedAssets.splice(index, 1)[0];

    setAssetForm(prevState => ({ ...prevState, assets: updatedAssets }));
    if (removedAsset.id) {
      setRemovedAssets(prevState => [...prevState, removedAsset.id]);
    }
  };

  const handleSeller = value => {
    setAssetForm(prevState => ({
      ...prevState,
      sellerId: parseInt(value?.id),
      sellerCode: value?.code,
      sellerName: value?.name
    }));
  };

  if (isLoading) return null;

  return (
    <>
      <Dialog
        open={isOpen}
        handleClose={handleClose}
        title={`Detalhes da Transação (${toTitle(transaction?.status)})`}
        pdMobile={"0 1rem 2rem"}
      >
        <Box component="form" onSubmit={onSubmitHandler} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <AutoCompleteSeller
                defaultValue={{
                  name: assetForm?.sellerName
                }}
                onChange={(_, newInputValue) => {
                  handleSeller(newInputValue);
                }}
                disabled={transactionCompleted}
              />
            </Grid>
            {assetForm.assets.map((asset, index) => (
              <React.Fragment key={asset.uniqueKey}>
                <Grid
                  container
                  alignItems="center"
                  spacing={1}
                  style={{ padding: "5px 8px" }}
                >
                  <Grid item xs={6} sm={7} md={7}>
                    <AutoCompleteAssets
                      defaultValue={{
                        id: assetForm?.assets[index]?.assetId,
                        name: assetForm?.assets[index]?.assetName
                      }}
                      disabled={transactionCompleted}
                      onChange={(_, newInputValue) => {
                        handleAsset(newInputValue, index);
                      }}
                    />
                  </Grid>
                  <Grid item xs={4} sm={4} md={4}>
                    <TextField
                      onChange={event => {
                        handleQty(event.target.value, index);
                      }}
                      type="number"
                      required
                      label="Movimentação"
                      value={assetForm?.assets[index]?.quantity}
                      autoComplete="off"
                      variant="outlined"
                      disabled={transactionCompleted}
                    />
                  </Grid>
                  <Grid item xs={2} sm={1} md={1}>
                    <IconButton
                      onClick={() => handleRemoveAsset(index)}
                      aria-label="Remover ativo"
                      disabled={transactionCompleted}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </React.Fragment>
            ))}
            {transaction?.status === "aberta" && (
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  size="large"
                  onClick={handleAssetQuantity}
                  style={{
                    backgroundColor: "#5a9952"
                  }}
                >
                  Adicionar ativo
                </Button>
              </Grid>
            )}
            <Grid item xs={12}>
              <h3>Assinatura:</h3>
              {!transactionCompleted && (
                <FileUpload onFileSelect={handleFileSelect} />
              )}
              {assetForm.assetSignatureUrl && (
                <div>
                  <img
                    src={
                      typeof assetForm.assetSignatureUrl === "string"
                        ? assetForm.assetSignatureUrl
                        : URL.createObjectURL(assetForm.assetSignatureUrl)
                    }
                    alt="Assinatura Carregada"
                    style={{
                      width: "100%",
                      maxWidth: "100%",
                      marginTop: "10px"
                    }}
                  />
                </div>
              )}
            </Grid>
            <Grid container item justifyContent="flex-end" spacing={2}>
              <Grid item>
                {!transactionCompleted && (
                  <Button
                    variant="contained"
                    size="large"
                    onClick={handleClose}
                    color="warning"
                  >
                    Cancelar
                  </Button>
                )}
              </Grid>
              <Grid item>
                {!transactionCompleted && (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="large"
                    disabled={
                      isBtnDisabled ||
                      isLoading ||
                      assetForm.assets.length === 0
                    }
                  >
                    Salvar
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Dialog>
    </>
  );
};
