import { Box, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import UploadIcon from "@material-ui/icons/CloudUpload";
import {
  ContainerPage,
  DialogUpload,
  PermissionGate,
  TitlePage,
  Button
} from "components";
import { OptionsMenu } from "components/OptionsMenu";
import { format } from "date-fns";
import { usePopup } from "hooks/usePopup";
import { useTableParams } from "hooks/useTableParams";
import { useEffect, useState } from "react";
import { MdOutlineAutoFixHigh, MdOutlineDelete } from "react-icons/md";
import storageService from "services/storageService";
import { Table } from "shared";
import {
  categoriesSelect,
  convertDate,
  reasonMap,
  subCategoriesSelect
} from "utils";

import { DialogConfirmDelete } from "./components/DialogConfirmDelete";
import { DialogConfirmType } from "./components/DialogConfirmType";
import { DialogCreateRegister } from "./components/DialogCreateRegister";
import { DialogEdit } from "./components/DialogEdit";

export default function SummaryControl() {
  const { data, onChange, params, refresh, status, count, handleSearch } =
    useTableParams({
      service: params => storageService.getStorageData({ ...params }),
      paramsDefault: {
        multipleFilter: true,
        orderDirection: "-updated",
        excludeReasonId: 9
      }
    });

  const { addPopup } = usePopup();

  const [reasons, setReasons] = useState([]);
  const [open, setOpen] = useState(false);
  const [dialogEdit, setDialogEdit] = useState({
    isOpen: false,
    data: {}
  });
  const [dialogConfirmDelete, setDialogConfirmDelete] = useState({
    isOpen: false,
    data: {}
  });
  const [dialogConfirmType, setDialogConfirmType] = useState({
    isOpen: false,
    convert: null,
    data: {},
    nameProduct: null
  });

  const [isUploadOpen, setIsUploadOpen] = useState(false);

  const options = [
    {
      title: "Excluir",
      icon: MdOutlineDelete,
      color: "red",
      permissions: ["Tech", "Estoque", "Estoque físico"],
      action: data => {
        setDialogConfirmDelete({
          isOpen: true,
          data
        });
      }
    },
    {
      title: "Corrigir",
      icon: MdOutlineAutoFixHigh,
      permissions: ["Tech", "Estoque", "Estoque físico"],
      action: data => {
        setDialogEdit({
          isOpen: true,
          data
        });
      }
    }
  ];

  const columns = [
    {
      field: "created",
      label: "Criado em",
      align: "center",
      disabledSort: true,
      style: { minWidth: "150px" },
      formatter: value => convertDate(new Date(value))
    },
    {
      field: "product_base",
      label: "Produto Base",
      disabledSort: true,
      style: { minWidth: "170px", maxWidth: "300px" },
      formatter: value => value?.name
    },
    {
      field: "to_be_accounted",
      label: "Contabilizar em",
      align: "center",
      disabledSort: true,
      style: { minWidth: "180px" },
      formatter: value => {
        if (value) {
          const formattedDate = format(new Date(value), "dd/MM/yy");
          return formattedDate;
        }

        return null;
      }
    },
    {
      field: "quantity",
      label: "Movimentação",
      align: "center",
      disabledSort: true,
      style: { minWidth: "120px" },
      formatter: value => {
        if (!value) return 0;
        return new Intl.NumberFormat("pt-BR").format(value.toFixed(2));
      },
      cellStyle: data => {
        if (data.quantity < 0) {
          return {
            color: "red"
          };
        }
      }
    },
    {
      field: "current_storage",
      label: "Novo estoque",
      align: "center",
      disabledSort: true,
      style: { minWidth: "120px" },
      formatter: value => {
        if (!value) return "Não informado";
        return new Intl.NumberFormat("pt-BR").format(value.toFixed(2));
      },
      cellStyle: data => {
        if (data.current_storage < 0) {
          return {
            color: "red"
          };
        }
      }
    },
    {
      field: "type",
      label: "Tipo",
      align: "center",
      disabledSort: true,
      style: { minWidth: "140px" },
      formatter: value => value?.name
    },
    {
      field: "reason",
      label: "Motivo",
      disabledSort: true,
      style: { minWidth: "180px" },
      renderCell: ({ data }) => {
        const { reason, order_id } = data;
        const orderInfoList = [2, 29, 30];
        const reasonValue = reasonMap(reason?.reason);

        return (
          <Typography style={{ fontSize: "0.9rem", color: "#414141" }}>
            {reasonValue}
            {orderInfoList.includes(reason?.id) && (
              <strong style={{ color: "#000000c7" }}>{` #${order_id}`}</strong>
            )}
          </Typography>
        );
      }
    },
    {
      field: "batch_id",
      label: "Lote",
      disabledSort: true,
      style: { minWidth: "140px", maxWidth: "200px" }
    },
    {
      field: "edited_for",
      label: "Responsável",
      disabledSort: true,
      style: { minWidth: "140px", maxWidth: "200px" }
    },
    {
      field: "seller",
      label: "Fornecedor",
      disabledSort: true,
      style: { minWidth: "180px", maxWidth: "300px" },
      formatter: value => value?.name
    },
    {
      field: "options",
      label: "",
      renderCell: ({ data }) =>
        !data.batch_id ? <OptionsMenu data={data} options={options} /> : null
    }
  ];

  const initialData = async () => {
    try {
      const { data } = await storageService.getReason();
      setReasons(
        data
          .filter(({ id }) => id !== 9)
          .map(reason => ({
            id: reason.id,
            label: reasonMap(reason.reason)
          }))
      );
    } catch (error) {
      if (error?.response?.status !== 401) {
        addPopup({
          type: "error",
          title: "Desculpe, ocorreu um erro",
          description: "Erro ao buscar motivos."
        });
      }
    }
  };

  const handleUpdateRegister = async data => {
    await storageService.registerUpdate(data);
  };

  const handleDeleteRegister = async id => {
    await storageService.registerDelete(id);
  };

  useEffect(() => {
    initialData();
  }, []);

  return (
    <>
      <ContainerPage>
        <Box display="flex" justifyContent="space-between" flexWrap="wrap">
          <TitlePage>Controle de Estoque</TitlePage>
        </Box>

        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          ml="auto"
          flexWrap="wrap"
          style={{ gap: "1rem" }}
        >
          <PermissionGate permissions={["Tech", "Estoque", "Estoque físico"]}>
            <Button
              variant="contained"
              size="medium"
              startIcon={<AddIcon />}
              onClick={() => {
                setOpen(true);
              }}
            >
              Novo registro
            </Button>
          </PermissionGate>
        </Box>

        <Table
          handleSearch={handleSearch}
          columns={columns}
          data={data}
          onChange={onChange}
          params={params}
          status={status}
          refresh={refresh}
          count={count}
          filterSubCategories
          filterCategories
          hover
          newFilterProps={[
            {
              parameter: "reasonId",
              label: "Motivo",
              values: reasons
            },
            {
              parameter: "categoryId",
              label: "Categoria",
              values: categoriesSelect.map(category => ({
                label: category.name,
                id: category.value
              }))
            },
            {
              parameter: "subcategoryId",
              label: "Subcategoria",
              values: subCategoriesSelect.map(category => ({
                label: category.name,
                id: category.value
              }))
            }
          ]}
        />
      </ContainerPage>

      <DialogUpload
        isOpen={isUploadOpen}
        service={storageService}
        handleClose={setIsUploadOpen}
        refresh={refresh}
        errorMessage="Erro ao atualizar o registro"
        successMessage="Registro atualizado com sucesso"
      />

      <DialogCreateRegister
        isOpen={open}
        handleConfirmType={setDialogConfirmType}
        reasons={reasons}
        setIsOpen={setOpen}
        refresh={refresh}
      />

      <DialogConfirmType
        isOpen={dialogConfirmType.isOpen}
        convert={dialogConfirmType.convert}
        data={dialogConfirmType.data}
        name={dialogConfirmType.nameProduct}
        setIsOpen={setDialogConfirmType}
        refresh={refresh}
      />

      <DialogEdit
        open={dialogEdit.isOpen}
        data={dialogEdit.data}
        handleClose={setDialogEdit}
        handleSubmit={handleUpdateRegister}
        refresh={refresh}
      />

      <DialogConfirmDelete
        open={dialogConfirmDelete.isOpen}
        data={dialogConfirmDelete.data}
        handleClose={setDialogConfirmDelete}
        handleConfirm={handleDeleteRegister}
        refresh={refresh}
      />
    </>
  );
}
