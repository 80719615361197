import { BsBoxSeam, BsArchive } from "react-icons/bs";
import {
  CgChart,
  CgFeed,
  CgGirl,
  CgTime,
  CgUser,
  CgUserList
} from "react-icons/cg";
import { FaStore, FaWpforms } from "react-icons/fa";
import { MdHome } from "react-icons/md";
import { RiPlantLine } from "react-icons/ri";

const NavConfig = [
  {
    title: "Dashboard",
    permissions: ["Stock View", "Tech", "Admin"],
    path: "/app/dashboard",
    icon: MdHome
  },
  {
    title: "Lotes",
    permissions: ["Reception", "Stock View", "Tech", "Admin"],
    icon: BsBoxSeam,
    children: [
      {
        title: "Recebimento",
        permissions: ["Reception", "Tech", "Admin"],
        path: "/app/recebimento"
      },
      {
        title: "Confirmar lotes",
        permissions: ["Stock View", "Estoque", "Tech", "Admin"],
        path: "/app/confirm-batch"
      },
      {
        title: "Conferência de recebimento",
        permissions: ["Stock View", "Tech", "Admin"],
        path: "/app/conferencia-de-recebimento"
      },
      {
        title: "Seleção",
        permissions: ["Stock View", "Tech", "Admin"],
        path: "/app/selecao"
      },
      {
        title: "Lotes",
        permissions: ["Stock View", "Tech", "Admin"],
        path: "/app/lotes"
      },
      {
        title: "SubLotes",
        permissions: ["Stock View", "Tech", "Admin"],
        path: "/app/sub-lotes"
      },
      {
        title: "Reembolso/Reposição",
        permissions: ["Stock View", "Estoque", "Tech", "Admin"],
        path: "/app/batch-return"
      },
      {
        title: "Alinhamento com o seller",
        permissions: ["Stock View", "Estoque", "Tech", "Admin"],
        path: "/app/alinhamento-com-o-seller"
      },
      {
        title: "Contagem",
        permissions: ["Stock View", "Estoque", "Tech", "Admin"],
        path: "/app/batch-count"
      }
    ]
  },
  {
    title: "Armazenamento",
    permissions: ["Admin", "Tech", "Stock View", "Estoque"],
    icon: FaStore,
    children: [
      {
        title: "A Armazenar/Armazenado",
        permissions: ["Admin", "Tech", "Stock View", "Estoque"],
        path: "/app/armazenamento"
      },
      {
        title: "Extrato",
        permissions: ["Admin", "Tech", "Stock View", "Estoque"],
        path: "/app/armazens-extrato"
      },
      {
        title: "Lotes armazenados",
        permissions: ["Admin", "Tech", "Stock View", "Estoque"],
        path: "/app/lotes-armazenados"
      },
      {
        title: "Configuraçôes",
        permissions: ["Tech", "Admin"],
        path: "/app/config"
      }
    ]
  },
  {
    title: "Estoque",
    permissions: ["Stock View", "Tech", "Admin"],
    icon: CgChart,
    children: [
      {
        title: "Extrato",
        permissions: ["Stock View", "Tech", "Admin"],
        path: "/app/estoque-extrato"
      },
      {
        title: "Resumo",
        permissions: ["Stock View", "Tech", "Admin"],
        path: "/app/estoque-resumo"
      },
      {
        title: "Resumo Virtual",
        permissions: ["Stock View", "Tech", "Admin"],
        path: "/app/estoque-resumo-virtual"
      },
      {
        title: "Resumo SKU",
        permissions: ["Stock View", "Tech", "Admin"],
        path: "/app/estoque-resumo-sku"
      },
      {
        title: "Físico",
        permissions: ["Stock View", "Tech", "Admin"],
        path: "/app/estoque-fisico"
      },
      {
        title: "Bloqueados",
        permissions: ["Stock View", "Tech", "Admin"],
        path: "/app/estoque-bloqueado"
      },
      {
        title: "Histórico",
        permissions: ["Stock View", "Tech", "Admin"],
        path: "/app/historico-estoque"
      }
    ]
  },
  {
    title: "Fornecedores",
    permissions: ["Stock View", "Tech", "Admin"],
    icon: CgGirl,
    children: [
      {
        title: "Fornecedores",
        permissions: ["Stock View", "Tech", "Admin"],
        path: "/app/fornecedores",
        icon: CgGirl
      },
      {
        title: "Ranking",
        permissions: ["Stock View", "Tech", "Admin"],
        path: "/app/fornecedores/ranking",
        icon: CgGirl
      }
    ]
  },
  {
    title: "Produto p/ fornecedor",
    permissions: ["Stock View", "Tech", "Admin"],
    path: "/app/produto-por-fornecedor",
    icon: CgFeed
  },
  {
    title: "Produtos",
    permissions: ["Stock View", "Tech", "Admin"],
    icon: RiPlantLine,
    children: [
      {
        title: "Produtos base",
        permissions: ["Stock View", "Tech", "Admin"],
        path: "/app/produtos"
      },
      {
        title: "Skus",
        permissions: ["Stock View", "Tech", "Admin"],
        path: "/app/skus"
      },
      {
        title: "Categorias",
        permissions: ["Stock View", "Tech", "Admin"],
        path: "/app/produtos/categorias"
      },
      {
        title: "Subcategorias",
        permissions: ["Stock View", "Tech", "Admin"],
        path: "/app/produtos/subcategorias"
      },
      {
        title: "Tipos de estoque",
        permissions: ["Stock View", "Tech", "Admin"],
        path: "/app/produtos/estocagem"
      }
    ]
  },
  {
    title: "Previsão de compra",
    permissions: ["Previsão", "Tech"],
    path: "/app/previsao-de-compra",
    icon: CgTime
  },
  {
    title: "Controle de Ativos",
    permissions: ["Stock View", "Estoque", "Tech"],
    icon: BsArchive,
    children: [
      {
        title: "Ativos",
        permissions: ["Stock View", "Estoque", "Tech", "Admin"],
        path: "/app/ativos"
      },
      {
        title: "Transações",
        permissions: ["Stock View", "Estoque", "Tech", "Admin"],
        path: "/app/ativos-transacoes"
      },
      {
        title: "Extrato",
        permissions: ["Stock View", "Estoque", "Tech", "Admin"],
        path: "/app/ativos-extrato"
      },
      {
        title: "Resumo",
        permissions: ["Stock View", "Estoque", "Tech", "Admin"],
        path: "/app/ativos-resumo"
      },
      {
        title: "Saldo por fornecedor",
        permissions: ["Stock View", "Estoque", "Tech", "Admin"],
        path: "/app/ativos-saldo-fornecedor"
      }
    ]
  },
  {
    title: "Regras",
    permissions: ["Stock View", "Tech"],
    icon: FaWpforms,
    children: [
      {
        title: "Blitz",
        path: "/app/regra-da-blitz"
      },
      {
        title: "Migração",
        path: "/app/regra-de-migracao"
      }
    ]
  },
  {
    title: "Grupos de Seleção",
    permissions: ["Stock View", "Tech", "Admin"],
    path: "/app/grupos-selecao",
    icon: CgUserList
  },
  {
    title: "Usuários",
    permissions: ["Tech", "Admin"],
    path: "/app/usuarios",
    icon: CgUser
  }
];

export default NavConfig;
