import { Grid, TextField, Box } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import CloudDownload from "@material-ui/icons/CloudDownload";
import UploadIcon from "@material-ui/icons/CloudUpload";
import { ContainerPage, Button, TitlePage, OptionsMenu } from "components";
import { usePopup } from "hooks/usePopup";
import { useTableParams } from "hooks/useTableParams";
import fileDownload from "js-file-download";
import { useEffect, useMemo, useState } from "react";
import { FiEdit2 } from "react-icons/fi";
import { MdOutlineDelete } from "react-icons/md";
import purchaseForecastService from "services/purchaseForecastService";
import { KeyboardDatePicker, Table } from "shared";
import { formatDate, typeMapping } from "utils";
import { searchWithoutAccent } from "utils/searchWithoutAccent";

import { ConfirmDeletePopup } from "./ConfirmPopup";
import { Create } from "./Create";
import { DialogUpload } from "./DialogUpload";
import { Edit } from "./Edit";
import InactiveProductDialog from "./InactiveProductDialog";
import { useStyles } from "./styles";

const filterForecast = (data, search) => {
  if (!search?.trim()) return data;

  return data.filter(predict => {
    const value = searchWithoutAccent(search ?? "");
    return (
      searchWithoutAccent(predict.product_base?.name ?? "").includes(value) ||
      searchWithoutAccent(predict.seller?.name ?? "").includes(value)
    );
  });
};

const PurchaseForecast = () => {
  const { addPopup } = usePopup();
  const [startDate, setStartDate] = useState(new Date());
  const [isDialogUpload, setIsDialogUpload] = useState(false);
  const [isLoadingConfirm, setIsLoadingConfirm] = useState(false);
  const classes = useStyles();
  const [deleteForecastPopup, setDeleteForecastPopup] = useState({
    isOpen: false,
    data: {}
  });
  const [editForecastPopup, setEditForecastPopup] = useState({
    isOpen: false,
    data: {}
  });
  const [inactiveConfirmation, setInactiveConfirmation] = useState({
    isOpen: false,
    data: {}
  });
  const [createForecastPopup, setCreateForecastPopup] = useState(false);
  const { data, params, onChange, status, refresh, count, handleSearch } =
    useTableParams({
      service: params => purchaseForecastService.getAll(params),
      paramsDefault: {
        date: formatDate(new Date())
      }
    });
  const [isMobile, setIsMobile] = useState(false);

  const handleUpdateDate = date => {
    setStartDate(date);
    onChange({ date: formatDate(date) });
  };

  const handleDownloadPurchaseForecast = () => {
    const year = startDate.getFullYear();
    const month = startDate.getMonth() + 1;
    const day = startDate.getDate();

    purchaseForecastService
      .getDownloadPurchaseForecast({ year, month, day })
      .then(({ data }) => fileDownload(data, "previsão_de_compra.xls"))
      .catch(err => {
        if (err?.response?.status !== 401) {
          addPopup({
            type: "error",
            title: "Erro ao fazer download"
          });
        }
      });
  };

  const handleInactiveProduct = (list = data) => {
    const inactiveProductList = list.filter(
      product =>
        (product.product_base?.status ||
          product.productBase?.status ||
          product.status) === "deactivated"
    );

    if (inactiveProductList.length) {
      setInactiveConfirmation({ isOpen: true, data: inactiveProductList });
    }
  };

  const handleConfirmForecast = () => {
    const year = startDate.getFullYear();
    const month = startDate.getMonth() + 1;
    const day = startDate.getDate();
    setIsLoadingConfirm(true);

    purchaseForecastService
      .generatePurchaseForecast({ year, month, day })
      .then(() => {
        addPopup({
          type: "success",
          title: "Previsão confirmada com sucesso"
        });
      })
      .catch(err => {
        if (err?.response?.status !== 401) {
          addPopup({
            type: "error",
            title: "Erro ao confirmar previsão"
          });
        }
      })
      .finally(() => setIsLoadingConfirm(false));
  };

  const options = [
    {
      title: "Excluir",
      icon: MdOutlineDelete,
      color: "red",
      permissions: ["Tech", "Estoque", "Estoque físico"],
      action: data => setDeleteForecastPopup({ isOpen: true, data })
    },
    {
      title: "Editar",
      icon: FiEdit2,
      permissions: ["Estoque", "Estoque físico", "Tech", "Previsão"],
      action: data => setEditForecastPopup({ isOpen: true, data })
    }
  ];

  const columns = [
    {
      field: "product_base",
      label: "Produto",
      disabled: true,
      style: { minWidth: "8rem" },
      formatter: data => data?.name
    },
    {
      field: "status",
      label: "Status",
      disabled: true,
      align: "center"
    },
    {
      field: "type",
      label: "Tipo",
      align: "center",
      disabled: true,
      formatter: value => typeMapping(value)
    },
    {
      field: "current_quantity",
      label: "Quantidade atual",
      align: "center",
      disabled: true,
      formatter: value =>
        new Intl.NumberFormat("pt-BR").format(value?.toFixed(2))
    },
    {
      field: "current_physical_quantity",
      label: "Estoque físico",
      align: "center",
      disabled: true,
      formatter: value =>
        new Intl.NumberFormat("pt-BR").format(value?.toFixed(2))
    },

    {
      field: "prediction",
      label: "Previsão Final",
      align: "center",
      disabled: true
    },
    {
      field: "combined_price",
      label: "Preço Kg/Un combinado",
      align: "center",
      disabled: true,
      formatter: value =>
        value?.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL"
        })
    },
    {
      field: "seller",
      label: "Seller",
      align: "center",
      disabled: true,
      style: { minWidth: "11.5rem" },
      formatter: data => data?.name ?? "--"
    },
    {
      field: "blitz_percentage",
      label: "% blitz",
      align: "center",
      style: { minWidth: "7rem" },
      disabled: true,
      formatter: data => (data * 100).toFixed(0)
    },
    {
      field: "refusal_instructions",
      label: "Devolução combinada",
      style: { minWidth: "17.8rem" },
      align: "left",
      disabled: true
    },
    {
      field: "deadline_for_analysis",
      label: "Prazo análise",
      style: { minWidth: "3rem" },
      align: "center",
      disabled: true
    },
    {
      field: "options",
      label: "",
      align: "right",
      style: { minWidth: "50px" },
      disabled: true,
      renderCell: ({ data, page }) => (
        <OptionsMenu data={data} options={options} page={page} />
      )
    }
  ];

  const results = useMemo(
    () => filterForecast(data, params.search),
    [data, params.search]
  );

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 767px)");

    const handleResize = () => {
      setIsMobile(mediaQuery.matches);
    };

    handleResize();
    mediaQuery.addListener(handleResize);
    return () => {
      mediaQuery.removeListener(handleResize);
    };
  }, []);

  return (
    <>
      <ContainerPage>
        <TitlePage>Previsão de compra</TitlePage>

        {!isMobile ? (
          <Grid container wrap="wrap" style={{ marginTop: 0 }}>
            <Grid
              container
              item
              xs={12}
              sm={6}
              justifyContent="flex-start"
              alignItems="flex-start"
              style={{ gap: "1rem", marginTop: "1rem" }}
              className={classes.grid}
            >
              <Button
                startIcon={<UploadIcon />}
                onClick={() => setIsDialogUpload(true)}
                isFullScreen
                variant="contained"
              >
                Upload
              </Button>

              <Button
                startIcon={<CloudDownload />}
                onClick={() => handleDownloadPurchaseForecast()}
                isFullScreen
                variant="contained"
              >
                Download
              </Button>
            </Grid>
          </Grid>
        ) : (
          <Grid container wrap="wrap" style={{ marginTop: 0 }}>
            <Grid
              container
              item
              xs={12}
              sm={6}
              justifyContent="flex-start"
              alignItems="flex-start"
              style={{ gap: "1rem" }}
              className={classes.grid}
            >
              <Button
                startIcon={<UploadIcon />}
                onClick={() => setIsDialogUpload(true)}
                isFullScreen
                variant="contained"
              >
                Upload
              </Button>

              <Button
                startIcon={<CloudDownload />}
                onClick={() => handleDownloadPurchaseForecast()}
                isFullScreen
                variant="contained"
              >
                Download
              </Button>
            </Grid>
          </Grid>
        )}

        <Table
          rowStyle={data => {
            if (data.product_base.status === "deactivated") {
              return { backgroundColor: "#FFDBDB" };
            }
            return {};
          }}
          filters={
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              flex="1"
            >
              <Box maxWidth="170px">
                <KeyboardDatePicker
                  fullWidth
                  disableToolbar
                  format="dd/MM/yyyy"
                  inputVariant="outlined"
                  value={startDate}
                  onChange={handleUpdateDate}
                  views={["date"]}
                />
              </Box>

              <Button
                onClick={() => setCreateForecastPopup(true)}
                variant="contained"
              >
                Nova previsão
              </Button>
            </Box>
          }
          columns={columns}
          data={results}
          onChange={onChange}
          params={params}
          status={status}
          refresh={refresh}
          count={count}
          paginationServer={false}
          handleSearch={handleSearch}
          placeholder="Produto, seller..."
          size="small"
          isMobile={isMobile}
        />
      </ContainerPage>

      <ConfirmDeletePopup
        open={deleteForecastPopup.isOpen}
        handleClose={() => setDeleteForecastPopup({ isOpen: false, data: {} })}
        reloadData={refresh}
        data={deleteForecastPopup.data}
      />

      <InactiveProductDialog
        open={inactiveConfirmation.isOpen}
        handleClose={() => setInactiveConfirmation({ isOpen: false, data: {} })}
        data={inactiveConfirmation.data}
      />

      <DialogUpload
        isOpen={isDialogUpload}
        refresh={refresh}
        handleClose={setIsDialogUpload}
        handleInactiveProduct={handleInactiveProduct}
        handleConfirmForecast={handleConfirmForecast}
      />

      <Edit
        refresh={refresh}
        isOpen={editForecastPopup.isOpen}
        setIsOpen={() => setEditForecastPopup({ isOpen: false, data: {} })}
        data={editForecastPopup.data}
        handleConfirmForecast={handleConfirmForecast}
      />

      <Create
        refresh={refresh}
        isOpen={createForecastPopup}
        handleInactiveProduct={handleInactiveProduct}
        setIsOpen={() => setCreateForecastPopup(false)}
        handleConfirmForecast={handleConfirmForecast}
      />
    </>
  );
};

export default PurchaseForecast;
